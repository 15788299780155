import React from "react"
import { Card, CardBody } from "reactstrap"

import leftArrowIcon from "../../../assets/images/icons/left-arrow-icon.svg"
import { truncateString } from "utils/commonUtils"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"

const COLUMNS = {
    SKU_NUMBER: "SKU No. ",
    ITEM_NAME: "Item Name",
    EXISTING_QTY: "Existing Qty",
    NEW_QTY: "New Qty",
    ADJUSTMENTS: "Adjustments",
}
const CHARACTER_LIMIT = 25
const SKU_CHARACTER_LIMIT = 15

const SelectedItemsModal = ({
    title,
    selectedItems,
    onClose,
    onSend,
    onBack,
    loading = false,
}) => {
    const getAdjustmentQtyClass = (newQuantity, existingItemQuantity) => {
        if (existingItemQuantity > newQuantity) {
            return "bg-red-light"
        } else if (existingItemQuantity < newQuantity) {
            return "bg-green-light"
        }
    }
    return (
        <>
            <Card className="mb-5">
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div onClick={onBack} className="table-clickable-column">
                        <img src={leftArrowIcon} className="me-1" />
                        <span>Back</span>
                    </div>

                    <h3 className="text-center inv-req-popup-heading mb-0">
                        {title}
                    </h3>
                    <div className="inv-modal">
                        <DataTable
                            data={selectedItems}
                            loading={false}
                            tableClasses="upload-inv-file-listing"
                            showCheckboxes={false}
                            config={[
                                {
                                    title: COLUMNS.SKU_NUMBER,
                                    render: data => {
                                        if (
                                            data?.skuNo?.length >
                                            SKU_CHARACTER_LIMIT
                                        ) {
                                            return (
                                                <div
                                                    data-container={data?.skuNo}
                                                >
                                                    {truncateString(
                                                        data?.skuNo,
                                                        SKU_CHARACTER_LIMIT
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return data?.skuNo || "-"
                                        }
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,

                                    render: data => {
                                        if (
                                            data?.itemName?.length >
                                            CHARACTER_LIMIT
                                        ) {
                                            return (
                                                <div
                                                    data-container={
                                                        data?.itemName
                                                    }
                                                >
                                                    {truncateString(
                                                        data?.itemName,
                                                        CHARACTER_LIMIT
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return data?.itemName || "-"
                                        }
                                    },
                                },

                                {
                                    title: COLUMNS.EXISTING_QTY,
                                    render: data => data?.existingItemQuantity,
                                },
                                {
                                    title: COLUMNS.NEW_QTY,
                                    render: data =>
                                        data?.newQuantity ==
                                        data?.existingItemQuantity
                                            ? "- "
                                            : data?.newQuantity,
                                },
                                {
                                    title: COLUMNS.ADJUSTMENTS,
                                    render: data => (
                                        <div
                                            className={`wh-im-global-quantity m-0  ${getAdjustmentQtyClass(
                                                Number(data?.newQuantity),
                                                Number(
                                                    data?.existingItemQuantity
                                                )
                                            )}`}
                                        >
                                            {data.adjustmentQuantity}
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>
            {selectedItems.length > 0 && (
                <div className="d-flex justify-content-center m-4 inv-item-listing-btn-con">
                    <Button
                        onClick={onClose}
                        size="sm"
                        title="Cancel"
                        className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header inv-item-listing-btn"
                    />

                    <Button
                        onClick={onSend}
                        isLoading={loading}
                        size="lg"
                        title="Update New"
                        className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header inv-item-listing-btn"
                    />
                </div>
            )}
        </>
    )
}

export default SelectedItemsModal
