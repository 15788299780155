import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"

import DownloadIcon from "../../../assets/images/icons/download-csv.png"
import { downloadCSV } from "utils/downloadUtils"
import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { truncateString } from "utils/commonUtils"

const COLUMNS = {
    CHECK_BOXES: "Select",
    SKU_NUMBER: "SKU No. ",
    ITEM_NAME: "Item Name",
    EXISTING_QTY: "Existing Qty",
    NEW_QTY: "New Qty",
}

const CHARACTER_LIMIT = 20
const SKU_CHARACTER_LIMIT = 10

const UploadInvFileListingModal = ({
    title,
    listingData,
    onNextButtonClick,
    loading = false,
}) => {
    const [selectedRows, setSelectedRows] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(true)

    useEffect(() => {
        setSelectedRows(listingData)
    }, [listingData])

    const handleNextButtonClick = () => {
        onNextButtonClick(selectedRows)
    }

    const handleDownloadClick = () => {
        downloadCSV(listingData, "inventory_listing.csv")
    }

    const handleCheckedBoxSelection = item => {
        setSelectedRows(prevSelectedItems => {
            if (prevSelectedItems.find(selected => selected.id === item.id)) {
                return prevSelectedItems.filter(
                    selected => selected.id !== item.id
                )
            } else {
                return [...prevSelectedItems, item]
            }
        })
    }

    const isItemSelected = item => {
        return (
            selectedRows.find(selected => selected.id === item.id) !== undefined
        )
    }

    const handleSelectAll = () => {
        if (selectedRows.length === listingData.length) {
            // If all items are already selected, clear the selection
            setSelectedRows([])
            setIsAllSelected(false)
        } else {
            // Otherwise, select all items
            setSelectedRows(listingData)
            setIsAllSelected(true)
        }
    }

    return (
        <>
            <Card className="mb-5">
                <CardBody className="p-2 wh-im-wh-gi-con">
                    <h3 className="text-center inv-req-popup-heading mb-0">
                        {title}
                    </h3>

                    <div
                        className="inv-sample-download  mt-0 mb-3"
                        onClick={handleDownloadClick}
                    >
                        <img
                            src={DownloadIcon}
                            alt="download icon"
                            className="download-img-icon pointer"
                        />
                        <span className="inv-sample-download-text pointer">
                            Download Comparison CSV
                        </span>
                    </div>
                    <div className="inv-modal">
                        <DataTable
                            data={listingData}
                            loading={loading}
                            tableClasses="upload-inv-file-listing"
                            config={[
                                {
                                    title: (() => (
                                        <div className="form-check">
                                            <TextInput
                                                onClick={() =>
                                                    handleSelectAll()
                                                }
                                                labelClass="mb-0"
                                                type="checkbox"
                                                inputClass="form-check-input-custom"
                                                checked={
                                                    selectedRows?.length !=
                                                    listingData?.length
                                                        ? false
                                                        : isAllSelected
                                                }
                                            />
                                        </div>
                                    ))(),
                                    render: data => (
                                        <div className=" ">
                                            <TextInput
                                                onClick={() =>
                                                    handleCheckedBoxSelection(
                                                        data
                                                    )
                                                }
                                                labelClass="mb-0"
                                                type="checkbox"
                                                inputClass="form-check-input-custom pointer"
                                                checked={isItemSelected(data)}
                                            />
                                        </div>
                                    ),
                                },
                                {
                                    title: COLUMNS.SKU_NUMBER,
                                    // render: data => <>{data?.skuNo || "-"}</>,
                                    render: data => {
                                        if (
                                            data?.skuNo?.length >
                                            SKU_CHARACTER_LIMIT
                                        ) {
                                            return (
                                                <div
                                                    data-container={data?.skuNo}
                                                >
                                                    {truncateString(
                                                        data?.skuNo,
                                                        SKU_CHARACTER_LIMIT
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return data?.skuNo || "-"
                                        }
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,
                                    // render: data => data?.itemName || "-",
                                    render: data => {
                                        if (
                                            data?.itemName?.length >
                                            CHARACTER_LIMIT
                                        ) {
                                            return (
                                                <div
                                                    data-container={
                                                        data?.itemName
                                                    }
                                                >
                                                    {truncateString(
                                                        data?.itemName,
                                                        CHARACTER_LIMIT
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return data?.itemName || "-"
                                        }
                                    },
                                },
                                {
                                    title: COLUMNS.EXISTING_QTY,
                                    render: data =>
                                        data?.existingItemQuantity || "-",
                                },
                                {
                                    title: COLUMNS.NEW_QTY,
                                    render: data =>
                                        data?.newQuantity ==
                                        data?.existingItemQuantity
                                            ? "- "
                                            : data?.newQuantity,
                                },
                            ]}
                            selectedRows={selectedRows}
                        />
                    </div>
                </CardBody>
            </Card>

            <div className="d-flex justify-content-center m-4">
                <Button
                    disabled={
                        listingData.length == 0 || selectedRows.length == 0
                    }
                    onClick={handleNextButtonClick}
                    size="lg"
                    title=" Next"
                    className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                />
            </div>
        </>
    )
}

export default UploadInvFileListingModal
